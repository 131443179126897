import React from 'react'

const NotFound404 = () => {
  return (
    <div className="container">
      <br/>
      <h3 className="title">404 Not Found</h3>
    </div>
  )
}

export default NotFound404
